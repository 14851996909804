//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex';
import i18n from '@/vueI18n'

export default {
  name: 'app-empty-permissions-page',

  methods: {
    ...mapActions({
      doSignout: 'auth/doSignout',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
  },
  computed:{
    language(){
      return i18n.locale
    }
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('auth.emptyPermissions.title') 
    },
  },
};
